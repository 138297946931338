(<template>
  <travel-rates-tab :message="getMessage">
    <sk-note v-if="minTravelDurationWarning"
             :text="minTravelDurationWarningText"
             class="travel-costs__note" />
    <div class="travel-costs__row travel-costs__row--input">
      <p class="travel-costs__title">
        <sk-tooltip :item-text="getTravelTimeTooltipText"
                    class="travel-costs__tooltip" />
        {{ $gettext('Travel time to and from:') }}
      </p>
      <div class="travel-costs__input-wrapper">
        <input v-model="travelDuration"
               class="travel-costs__input"
               type="tel"
               pattern="[0-9]*"
               inputmode="numeric"
               placeholder="0"
               tabindex="1"
               @input="handleTravelDuration($event)" />
        <span class="travel-costs__currency">{{ $gettext('Hr(s)') }}</span>
      </div>
    </div>
    <p v-if="travelDurationError || maxTravelDurationError"
       class="travel-costs__custom-error">{{ travelDurationError || maxTravelDurationError }}</p>
    <div class="travel-costs__row travel-costs__row--input">
      <p class="travel-costs__title">
        <sk-tooltip :item-text="getTravelAllowanceTooltipText"
                    class="travel-costs__tooltip" />
        {{ $gettext('Public transport ticket(s):') }}
      </p>
      <div class="travel-costs__input-wrapper">
        <input v-model="travelAllowance"
               :disabled="minTravelDurationWarning"
               class="travel-costs__input"
               type="tel"
               pattern="[0-9]*"
               inputmode="numeric"
               placeholder="0"
               tabindex="2"
               @input="handleTravelAllowance($event)" />
        <span class="travel-costs__currency">{{ currency }}</span>
      </div>
    </div>
    <p v-if="travelAllowanceError || (maxTravelInvoiceError && !maxTravelDurationError)"
       class="travel-costs__custom-error">{{ travelAllowanceError || maxTravelInvoiceError }}</p>
  </travel-rates-tab>
</template>)

<script>
  import {mapState} from 'vuex';
  import TravelRatesTabPrototype from '@/components/assignment_components/one_assignment/interactive_info/pt_applying/travel_costs_apply/TravelRatesTabPrototype';
  import TravelRatesTab from '@/components/assignment_components/one_assignment/interactive_info/pt_applying/travel_costs_apply/TravelRatesTab';

  export default {
    components: {
      'travel-rates-tab': TravelRatesTab
    },
    extends: TravelRatesTabPrototype,
    computed: {
      ...mapState('AssignmentFlowStore', {
        travelDurationError: ({errors}) => errors.travelDuration,
        travelAllowanceError: ({errors}) => errors.travelAllowance
      }),
      getMessage() {
        return this.$gettext('Unreasonable ticket prices will not be refunded. We retain the right to ask for receipts as proof. For rules regarding cost of diet please <a class="sk-link sk-link--gray-bg" href="https://arbeidsgiver.difi.no/lonn-goder-og-reise/reise/statens-satser-innenlands">click here.</a>');
      },
      getTravelTimeTooltipText() {
        return this.$gettext('Enter the amount of hours it will take you to travel to and from the assignment.');
      },
      getTravelAllowanceTooltipText() {
        return this.$gettext('Enter the amount it will cost you to travel to and from the assignment. Unreasonable ticket prices will not be refunded. We retain the right to ask for receipts as proof.');
      }
    },
    beforeDestroy() {
      this.$store.commit('AssignmentFlowStore/clearPublicTransportData');
    }
  };
</script>
