(<template>
  <travel-rates-tab :message="getMessage">
    <sk-note v-if="minTravelDurationWarning"
             :text="minTravelDurationWarningText"
             class="travel-costs__note" />
    <sk-note :text="includeFuelText"
             class="travel-costs__note" />

    <div class="travel-costs__row">
      <p class="travel-costs__title">
        <sk-tooltip :item-text="getDistanceTooltipText"
                    class="travel-costs__tooltip" />
        {{ $gettext('Distance to and from:') }}
      </p>
      <div class="travel-costs__price-wrapper"
           v-html="getDistanceBlock"></div>
    </div>
    <p v-if="maxTravelDistanceError"
       class="travel-costs__custom-error">{{ maxTravelDistanceError }}</p>

    <div class="travel-costs__row">
      <p class="travel-costs__title">
        <sk-tooltip :item-text="getDrivingTimeTooltipText"
                    class="travel-costs__tooltip" />
        {{ $gettext('Driving time to and from:') }}
      </p>
      <div class="travel-costs__price-wrapper"
           v-html="getTravelTimeBlock"></div>
    </div>
    <p v-if="maxTravelDurationError"
       class="travel-costs__custom-error">{{ maxTravelDurationError }}</p>
  </travel-rates-tab>
</template>)

<script>
  import TravelRatesTabPrototype from '@/components/assignment_components/one_assignment/interactive_info/pt_applying/travel_costs_apply/TravelRatesTabPrototype';
  import TravelRatesTab from '@/components/assignment_components/one_assignment/interactive_info/pt_applying/travel_costs_apply/TravelRatesTab';

  export default {
    components: {
      'travel-rates-tab': TravelRatesTab
    },
    extends: TravelRatesTabPrototype,
    computed: {
      travelDuration() { return this.travelCostsPricing.travelDuration || 0; },
      travelDistance() { return this.travelCostsPricing.travelDistance || 0; },
      distance() { return Number(this.travelDistance).toFixed(2); },
      includeFuelText() { return this.$gettext('Note: This includes fuel'); },
      getMessage() {
        return this.$gettext('For rules regarding cost of diet please <a class="sk-link sk-link--gray-bg" href="https://arbeidsgiver.difi.no/lonn-goder-og-reise/reise/statens-satser-innenlands">click here.</a>');
      },
      getDistanceBlock() {
        const template = this.$gettext('<span class="travel-costs__price">%{ distance }</span><span class="travel-costs__currency">KM</span>');
        return this.$gettextInterpolate(template, {distance: this.distance});
      },
      getTravelTimeBlock() {
        const template = this.$gettext('<span class="travel-costs__price">%{ travelDuration }</span><span class="travel-costs__currency">hr(s)</span>');
        return this.$gettextInterpolate(template, {travelDuration: this.travelDuration.toFixed(2)});
      },
      getDistanceTooltipText() {
        return this.$gettext('The platform automatically calculates how many km the assignment location is from your home and back.');
      },
      getDrivingTimeTooltipText() {
        return this.$gettext('For this assignment the customer has set a maximum travel time they are able to pay.');
      },
      maxTravelDistanceError() {
        return this.distance && this.distance >= this.maxTravelDistance ? this.getMaxTravelDistanceErrorText() : '';
      },
      maxTravelDurationError() {
        return Number(this.travelDuration) >= this.maxTravelDuration ? this.getTravelDurationErrorText() : '';
      }
    },
    methods: {
      getTravelDurationErrorText() {
        const template = this.$gettext('Note: Maximum payable travel duration for %{enterpriseName} is %{n} hours');
        return this.$gettextInterpolate(template, {enterpriseName: this.enterpriseName, n: this.maxTravelDuration});
      },
      getMaxTravelDistanceErrorText() {
        const template = this.$gettext('Note: Maximum payable distance for %{enterpriseName} is %{limit} km');
        return this.$gettextInterpolate(template, {enterpriseName: this.enterpriseName, limit: this.maxTravelDistance});
      }
    }
  };
</script>
