<script>
  import helpers from '@/helpers';
  import {mapMutations, mapState, mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('AssignmentFlowStore', ['travelCostsPricing']),
      ...mapState('OneAssignmentStore', {
        priceRequirement: (state) => state.storeJobObj.priceRequirement || {},
        demanderInfo: (state) => state.storeJobObj.info || {}
      }),
      ...mapState('AssignmentFlowStore', {
        travelDurationStored: (state) => state.travelDuration,
        travelAllowanceStored: (state) => state.travelAllowance
      }),
      // common info
      enterpriseName() { return this.demanderInfo.owner || ''; },
      currency() { return 'NOK'; },
      // travel cost info
      maxTravelInvoice() { return this.travelCostsPricing.maxAllowanceAmount || null; },
      travelDurationCliff() { return this.travelCostsPricing.travelDurationCliff; },
      travelDurationDeduction() { return this.travelCostsPricing.travelDurationDeduction; },
      travelRatePerHour() { return this.travelCostsPricing.travelRatePerHour; },
      maxTravelDuration() { return this.travelCostsPricing.maxTravelDuration; },
      maxTravelDistance() { return this.travelCostsPricing.maxTravelDistance || 400; },
      travelFlatAmountThresholdHours() { return this.travelCostsPricing.travelFlatAmountThresholdHours || 0; },
      minTravelDuration() { return Number(this.travelFlatAmountThresholdHours).toFixed(2); },
      minTravelDurationPrice() { return this.travelCostsPricing.travelFlatAmountValue || 0; },
      travelDuration: {
        get(state) {
          return state.travelDurationStored || '';
        },
        set(value) {
          this.removeOneError('travelDuration');
          this.setTravelDuration(value);
        }
      },
      travelAllowance: {
        get(state) {
          return state.travelAllowanceStored || '';
        },
        set(value) {
          this.removeOneError('travelAllowance');
          this.setTravelAllowance(value);
        }
      },
      // text properties
      maxTravelInvoiceError() {
        if (this.maxTravelInvoice
          && !Number.isNaN(+this.travelAllowance)
          && this.travelAllowance > +this.maxTravelInvoice) {
          this.setError({
            fieldName: 'maxTravelInvoice',
            errorText: this.getMaxTravelInvoiceErrorText()
          });
          return this.getMaxTravelInvoiceErrorText();
        } else {
          this.removeOneError('maxTravelInvoice');
          return '';
        }
      },
      maxTravelDurationError() {
        if (Number(this.travelDuration) > this.maxTravelDuration) {
          this.setError({
            fieldName: 'maxTravelDuration',
            errorText: this.getTravelDurationErrorText()
          });
          return this.getTravelDurationErrorText();
        } else {
          this.removeOneError('maxTravelDuration');
          return '';
        }
      },
      notPayForLocalTravel() {
        if (Number(this.travelDuration) && this.travelDurationCliff && this.travelDurationCliff != 0) {
          return Number(this.travelDuration) * 60 <= this.travelDurationCliff;
        } else {
          return false;
        }
      },
      notPayForFirstHoursTravel() {
        return this.travelDurationDeduction && this.travelDurationDeduction != 0;
      },
      minTravelDurationWarning() {
        return !!(Number(this.travelDuration) && this.minTravelDuration > Number(this.travelDuration));
      },
      minTravelDurationWarningText() {
        const template = this.$gettext('This customer pays a fixed amount for travel to the assignment address: %{priceAmount} NOK. Expenses will not be paid.');
        return this.$gettextInterpolate(template, {
          priceAmount: this.minTravelDurationPrice
        });
      }
    },
    methods: {
      ...mapMutations('AssignmentFlowStore', [
        'setTravelDuration',
        'setTravelAllowance',
        'setError',
        'removeOneError'
      ]),
      handleTravelAllowance(event) {
        const cleanedValue = helpers.getOnlyDigits(event.target.value, true);
        event.target.value = cleanedValue;
        this.travelAllowance = cleanedValue;
      },
      handleTravelDuration(event) {
        const cleanedValue = helpers.getOnlyDigits(event.target.value, true);
        event.target.value = cleanedValue;
        this.travelDuration = cleanedValue;
      },
      getTravelDurationErrorText() {
        const template = this.$gettext('The maximum travel duration is %{n} hours');
        return this.$gettextInterpolate(template, {n: this.maxTravelDuration});
      },
      getMaxTravelInvoiceErrorText() {
        const template = this.$gettext('%{enterpriseName} has a %{limit} %{currency} limit on travel allowance for this booking');
        return this.$gettextInterpolate(template, {enterpriseName: this.enterpriseName, limit: this.maxTravelInvoice, currency: this.currency});
      }
    }
  };
</script>
