(<template>
  <shared-pricing :hide-total="true"
                  class="pt-travel-cost-apply">
    <p class="pt-travel-cost-apply__title">{{ $gettext('Choose how you will travel to this assignment') }}</p>

    <distance-block :is-editable="true"
                    :show-search-field="showSearchField"
                    class="pt-travel-cost-apply__distance-section"
                    @onsave="updateTravelFromAddress"
                    @setshowsearchfield="setShowSearchField" />

    <travel-type :is-editable="true"
                 class="pt-travel-cost-apply__travel-type"
                 @traveltypechanged="setTravelType" />

    <div v-if="!isWithoutTravel">
      <sk-note v-if="isFlatAmount"
               :text="minTravelDistanceWarning"
               class="travel-costs__note" />
      <sk-note v-else-if="maxAllowanceAmount"
               :text="maxAllowanceAmountWarning"
               class="travel-costs__note" />
    </div>

    <component :is="component.name"
               v-for="component in componentsListContent"
               :key="component.name" />

    <div v-if="!travelType && !isFlatAmount"
         class="pt-travel-cost-apply__message">{{ $gettext('Please choose a travel method to apply for this assignment') }}</div>
    <expense-warning-banner v-if="travelType && !isWithoutTravel"
                            :receipt-confirm="receiptConfirm"
                            :allowance-amount="Number(maxAllowanceAmount)"
                            :any-travel-expenses="anyTravelExpensesCovered"
                            class="pt-travel-cost-apply__expense-warning"
                            @confrimchanged="setReceiptConfirm" />
    <apply-actions>
      <button slot="applyButton"
              :disabled="isDisabledApply"
              class="sk-btn sk-btn--default pt-travel-cost-apply__apply-btn"
              @click="sendApply">{{ $gettext('Calculate and Apply') }}</button>
    </apply-actions>
  </shared-pricing>
</template>)

<script>
  import {mapState, mapGetters, mapMutations} from 'vuex';
  import helpers from '@/helpers';
  import ApplyPrototype from '@/prototypes/one_assignment_page/ApplyPrototype';
  import SharedPricing from '@/components/assignment_components/one_assignment/interactive_info/shared_components/SharedPricing';
  // price template based components
  import TravelType from '@/components/assignment_components/one_assignment/interactive_info/shared_components/TravelType';
  import ApplyActions from '@/components/assignment_components/one_assignment/interactive_info/pt_actions/ApplyActions';
  import DistanceBlock from '@/components/assignment_components/one_assignment/interactive_info/shared_components/DistanceBlock';
  // travel costs based components
  import PublicTransportTab from '@/components/assignment_components/one_assignment/interactive_info/pt_applying/travel_costs_apply/PublicTransportTab';
  import DrivingTab from '@/components/assignment_components/one_assignment/interactive_info/pt_applying/travel_costs_apply/DrivingTab';
  import WarningBanner from '@/components/shared_components/banners/RecepitWarningBanner';

  export default {
    components: {
      'shared-pricing': SharedPricing,
      'distance-block': DistanceBlock,
      'travel-type': TravelType,
      'apply-actions': ApplyActions,
      'driving-tab': DrivingTab,
      'public-transport-tab': PublicTransportTab,
      'expense-warning-banner': WarningBanner
    },
    extends: ApplyPrototype,
    data() {
      return {
        showSearchField: false,
        receiptConfirm: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid', 'userIsOrgInterpreter']),
      ...mapGetters('AssignmentFlowStore', [
        'travelCostsPricing',
        'travelFromAddress'
      ]),
      ...mapState('OneAssignmentStore', {
        allowedTravelTypes: ({storeJobObj}) => {
          return storeJobObj.priceRequirement ? storeJobObj.priceRequirement.allowedTravelTypes || [] : [];
        }
      }),
      ...mapState('AssignmentFlowStore', {
        travelType: ({travelType}) => travelType,
        travelDuration: ({travelDuration}) => travelDuration || 0,
        travelAllowance: ({travelAllowance}) => travelAllowance || '',
        maxTravelInvoiceError: ({errors}) => errors.maxTravelInvoice,
        maxTravelDurationError: ({errors}) => errors.maxTravelDuration,
        previewData: ({previewData}) => previewData || ''
      }),
      travelRate() { return this.travelCostsPricing.travelRatePerHour; },
      travelFromLongitude() { return this.travelCostsPricing.travelFromLongitude; },
      travelFromLatitude() { return this.travelCostsPricing.travelFromLatitude; },
      travelDistance() { return this.travelCostsPricing.travelDistance || 0; },
      minTravelDistancePrice() { return this.travelCostsPricing.travelFlatAmountValue || 0; },
      distance() { return Number(this.travelDistance).toFixed(2); },
      isDisabledApply() {
        const seriesJobConfirmation = this.isSameSupplierSeriesJob && !this.seriesJobConfirmation;
        const isDisabledForNotFlatAmountJobs = !this.travelType && !this.isFlatAmount;
        const receiptConfirmation = this.isInPersonJob && !this.receiptConfirm && !this.isWithoutTravel;

        return !this.travelFromAddress || seriesJobConfirmation
          || isDisabledForNotFlatAmountJobs
          || receiptConfirmation;
      },
      // Travel rate data
      isWithoutTravel() { return this.travelType === 'no_travel'; },
      isTravelCar() { return this.travelType === 'car'; },
      isTravelPublicTransport() { return this.travelType === 'public_transport'; },
      isFlatAmount() { return !helpers.priceTemplates.isNotFlatAmount(this.travelCostsPricing); },
      componentsList() {
        return [
          {name: 'public-transport-tab', condition: this.isTravelPublicTransport && this.allowedTravelTypes.includes('public_transport')},
          {name: 'driving-tab', condition: this.isTravelCar && this.allowedTravelTypes.includes('car')}
        ];
      },
      componentsListContent() { return this.componentsList.filter(({condition}) => condition); },
      minTravelDistanceWarning() {
        const template = this.$gettext('This customer pays a fixed amount for travel to the assignment address: %{p} NOK.');
        return this.$gettextInterpolate(template, {p: this.minTravelDistancePrice});
      },
      maxAllowanceAmount() { return this.travelCostsPricing.maxAllowanceAmount || 0; },
      maxAllowanceAmountWarning() {
        const template = this.$gettext('This customer pays a maximum of <b>%{amount} %{currency}</b> for expenses');

        return this.$gettextInterpolate(template, {
          amount: this.maxAllowanceAmount,
          currency: this.currency
        });
      },
      currency() { return this.$store.getters['UserInfoStore/userCurrency']?.name || 'NOK'; },
      isInPersonJob() { return this.previewData[0]?.sessionType === 'in_person'; },
      anyTravelExpensesCovered() { return this.travelCostsPricing.anyTravelExpensesCovered; }
    },
    methods: {
      ...mapMutations('AssignmentFlowStore', [
        'setError',
        'setTravelType',
        'removeOneError'
      ]),
      setShowSearchField(value) {
        this.showSearchField = value;
      },
      updateTravelFromAddress() {
        const params = this.travelType ? {travel_by: this.travelType} : {};
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('AssignmentFlowStore/getInterInvoicePreview', {
          jobId: this.$route.params.id,
          params
        }).then(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          this.setShowSearchField(false);
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      },
      transportValid() {
        let isValidForm = true;
        this.removeOneError('travelDuration');
        this.removeOneError('travelAllowance');
        if (helpers.isInvalidNotNegativeNumber(this.travelDuration)) {
          this.setError({
            fieldName: 'travelDuration',
            errorText: this.$gettext('Please apply with a valid travel time round trip')
          });
          isValidForm = false;
        } else {
          if (this.maxTravelDurationError) isValidForm = false;
        }
        if (this.travelAllowance && helpers.isInvalidNotNegativeNumber(this.travelAllowance)) {
          this.setError({
            fieldName: 'travelAllowance',
            errorText: this.$gettext('Please apply with a valid travel allowance')
          });
          isValidForm = false;
        }
        if (this.maxTravelInvoiceError) isValidForm = false;
        return isValidForm;
      },
      drivingValid() {
        let isValidForm = true;
        if (this.distance === 0) {
          isValidForm = false;
        }
        return isValidForm;
      },
      isValidForm() {
        if (!this.isDisabledApply) {
          if (this.isTravelPublicTransport) {
            return this.transportValid();
          } else if (this.isTravelCar) {
            return this.drivingValid();
          } else {
            return true;
          }
        }
      },
      sendApply() {
        if (this.isValidForm()) {
          const applyObj = {
            travel_cost: {
              travel_time: Number(this.travelDuration),
              travel_allowance: Number(this.travelAllowance)
            }
          };
          if (this.travelType && this.travelType !== 'no_travel') {
            applyObj.travel_cost.travel_type = this.travelType;
          }
          if (this.isTravelCar) {
            applyObj.travel_cost.travel_from_longitude = this.travelFromLongitude;
            applyObj.travel_cost.travel_from_latitude = this.travelFromLatitude;
          }
          // do not send values to BE that cannot be edited
          if (!this.userIsOrgInterpreter) applyObj.travel_cost.hour_rate = this.travelRate;
          this.handleApply(applyObj);
        }
      },
      setReceiptConfirm(value) {
        this.receiptConfirm = value;
      }
    }
  };
</script>

<style scoped src="@/assets/tikktalk/css/travel_rate_job_tab.css"></style>

<style scoped>
  .pt-travel-cost-apply__title {
    margin: 20px 0;
    font-weight: bold;
    font-size: 18px;
  }

  .pt-travel-cost-apply__travel-type,
  .pt-travel-cost-apply__distance-section {
    margin-bottom: 20px;
  }

  .pt-travel-cost-apply__message {
    margin-bottom: -15px;
  }

  .pt-travel-cost-apply__expense-warning {
    margin-top: 20px;
  }
</style>
