(<template>
  <div class="travel-cost-apply__travel-costs">
    <sk-note v-if="notPayForLocalTravel"
             :text="notPayForLocalTravelMessage"
             class="travel-costs__note" />
    <sk-note v-if="notPayForFirstHoursTravel"
             :text="notPayForFirstHoursTravelMessage"
             class="travel-costs__note" />
    <div class="travel-costs__offer">
      <slot></slot>
    </div>
    <div class="travel-costs__info">
      <div class="travel-costs__info-row">
        <p class="travel-costs__message"
           v-html="flightMessage"></p>
        <p v-if="userIsOrgInterpreter"
           class="travel-costs__message"
           v-html="organizationTip"></p>
        <p class="travel-costs__message"
           v-html="message"></p>
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import TravelRatesTabPrototype from '@/components/assignment_components/one_assignment/interactive_info/pt_applying/travel_costs_apply/TravelRatesTabPrototype';

  export default {
    extends: TravelRatesTabPrototype,
    props: {
      message: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        supportPortalLink: this.$getString('domainData', 'supportPortalLink'),
        platformName: this.$getString('domainData', 'platformName')
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsOrgInterpreter']),
      notPayForLocalTravelMessage() {
        return this.$gettext('This enterprise does not pay for local travel.');
      },
      notPayForFirstHoursTravelMessage() {
        const template = this.$gettext('This enterprise is paying %{deductionTime} hours less for traveling. Please note that this is the customer choice, and is out of %{domain}\'s control.');
        return this.$gettextInterpolate(template, {
          deductionTime: this.travelDurationDeduction / 60,
          domain: this.$getString('domainData', 'platformName')
        });
      },
      flightMessage() {
        const template = this.$gettext('If you need to travel by plane or need to book a hotel for this assignment please <a class="sk-link sk-link--gray-bg" href="%{link}">contact %{domain}.</a>');
        return this.$gettextInterpolate(template, {domain: this.platformName, link: this.supportPortalLink});
      },
      organizationTip() {
        const template = this.$gettext('The rate applies if you invoice %{domain} directly.');
        return this.$gettextInterpolate(template, {domain: this.platformName});
      }
    }
  };
</script>

<style scoped src="@/assets/tikktalk/css/travel_rate_job_tab.css"></style>

<style>
  .travel-costs__tooltip .sk-tooltip__btn {
    position: absolute;
    top: 0;
    left: 0;
  }

  .travel-cost-apply__travel-costs .travel-costs__currency {
    margin-left: 5px;
  }
</style>

<style scoped>
  .travel-cost-apply__travel-costs {
    display: block;
    width: 100%;
  }
</style>
